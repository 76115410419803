const PluginEssenciais = {
    "nome": "cookieConsentPluginEssenciais",
    "pluginName": "PluginEssenciais",
    init: function(){
        const that = this;

        const idioma = document.getElementById('cookieConsentV2Idioma').innerText;
        const traducao = require('../'+idioma+'.json');

        window[that.pluginName] = that;
        const html = `
            <div class="cookieConsentPlugin">
                <div class="cookieConsentPluginTexto">
                    ${traducao.plugins.essenciais.titulo}
                    <sub>${traducao.plugins.essenciais.legenda}</sub>
                </div>
                <div class="cookieConsentPluginField">
                    <input type="checkbox" checked disabled name="${that.nome}" value="S" />
                </div>
            </div>
        `;
        return html;
    },
    snippet: function(){
        // Nada a fazer aqui
    }
}

export {
    PluginEssenciais
}