import { GereCookies } from "./gereCookies.js";



const HTML = {

    idioma: 'pt',

    createHTML: function(PluginsUsados){

        const that = this;
        that.idioma = document.getElementById('cookieConsentV2Idioma').innerText;

        const traducao = require('./'+that.idioma+'.json');

        const html = `
            <div class="cookieConsent">
                <div class="cookieConsentMensagemInicial">
                    <div class="cookieConsentMensagemInicialTopo">${traducao.titulo}</div>
                    <div class="cookieConsentMensagemInicialColunas">
                        <div class="cookieConsentMensagemInicialCentro">
                        ${traducao.resumo}
                    </div>
                    <div class="cookieConsentMensagemInicialDireita">
                        <button class="cookieConsentButton" data-cookieconsent="maisopcoes">${traducao.maisOpcoes}</button>
                        <button class="cookieConsentButton cookieConsentButtonDefault" data-cookieconsent="aceitartodos">${traducao.aceitarTodos}</button>
                    </div>
                    </div>
                </div>
            </div>
            <div class="cookieConsentDetalhe">
                <div class="cookieConsentDetalheWrapper">
                    ${traducao.textoCompleto01}
                        <div id="cookieConsentPluginsAqui">
                            <h2>${traducao.decida}</h2>
                        </div>
                        <div class="cookieConsentButoes">
                            <button class="cookieConsentButton cookieConsentButtonDefault" data-cookieconsent="gravarpreferencias">${traducao.gravarPreferencias}</button>
                        </div>
                </div>
            </div>
        `;
        const el = document.createElement('div');
        el.id = "cookieConsentWrapper";
        el.innerHTML = html;
        document.querySelector('body').appendChild(el);

        that.inserirPlugins(PluginsUsados);
        that.insertListenners(PluginsUsados);

    },
    inserirPlugins: function(PluginsUsados){

        const localDeInsercao = document.getElementById('cookieConsentPluginsAqui');

        PluginsUsados.forEach(function(plugin){
            const html = plugin.init();
            localDeInsercao.innerHTML += html;
        })

    },
    insertListenners: function(PluginsUsados){
        const that = this;
        const botaoMaisOpcoes = document.querySelector('[data-cookieconsent="maisopcoes"]');
        botaoMaisOpcoes.addEventListener('click', function(){
            that.mostrarOpcoes();
        });

        const botaoAceitarTodos = document.querySelector('[data-cookieconsent="aceitartodos"]');
        botaoAceitarTodos.addEventListener('click', function(){
            that.aceitarTodos(PluginsUsados);
        });

        const botaoGravarPreferencias = document.querySelector('[data-cookieconsent="gravarpreferencias"]');
        botaoGravarPreferencias.addEventListener('click', function(){
            that.gravarPreferencias(PluginsUsados);
        })
    },
    mostrarOpcoes: function(){
        // Escondo o elemento pequeno
        document.querySelector('.cookieConsent').style.display = 'none';
        // Mostra o elemento grande
        document.querySelector('.cookieConsentDetalhe').style.display = 'block';
    },
    aceitarTodos: function(PluginsUsados){

        let cookieObject = {

        };

        PluginsUsados.forEach(function(plugin){
            const nomeInput = plugin.nome;
            const el = document.querySelector('input[name="' + nomeInput + '"]');
            let nome = plugin.pluginName;
            const valor = 'S';
            cookieObject[nome] = {
                "plugin": plugin.pluginName,
                "valor": valor
            }
        });

        GereCookies.gravaCookie(cookieObject);

        // Escondo o elemento pequeno
        document.querySelector('.cookieConsent').style.display = 'none';
        // Mostra o elemento grande
        document.querySelector('.cookieConsentDetalhe').style.display = 'none';

    },
    gravarPreferencias: function(PluginsUsados){
        const that = this;

        let cookieObject = {

        };

        PluginsUsados.forEach(function(plugin){
            const el = document.querySelector('input[name="' + plugin.nome +'"]');
            let valor = 'N';
            if(el.checked){
                valor = 'S';
            }else{
                valor = 'N';
            }

            cookieObject[plugin.nome] = {
                "plugin": plugin.pluginName,
                "valor": valor
            }

        });

        GereCookies.gravaCookie(cookieObject);

        document.querySelector('.cookieConsentDetalhe').style.display = 'none';

    }

}

export {
    HTML
}





