import { CSS } from './jsSiteDev/css.js';
import { HTML } from './jsSiteDev/html.js';
import { GereCookies } from './jsSiteDev/gereCookies.js';

// Plugins Ativos
import { PluginEssenciais } from "./jsSiteDev/plugins/pluginEssenciais.js";
import { PluginAnalitycs } from "./jsSiteDev/plugins/pluginAnalytics.js";

const PluginsUsados = [
    PluginEssenciais,
    PluginAnalitycs
]

const haCookie = GereCookies.verificaCookie();

if(!haCookie){
    CSS.createSheet();
    HTML.createHTML(PluginsUsados);
}else{
    // Executa as ações dos cookies escolhidos
    const cookieGravado = GereCookies.obtemCookie();
    const valores = Object.values(cookieGravado);
    valores.forEach(function(item){
        if(item.valor === 'S'){
            PluginsUsados.forEach(function(plugin){
                if(plugin.pluginName === item.plugin){
                    plugin.snippet();
                }
            })
        }
    })
}

