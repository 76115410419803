

const PluginAnalitycs = {
    nome: "cookieConsentPluginAnalytics",
    "pluginName": "PluginAnalitycs",
    analyticsId: "G-V59PXSNWDW",
    init: function(){
        const that = this;
        const idioma = document.getElementById('cookieConsentV2Idioma').innerText;
        const traducao = require('../'+idioma+'.json');

        window[that.pluginName] = that;
        const html = `
            <div class="cookieConsentPlugin">
                <div class="cookieConsentPluginTexto">
                    ${traducao.plugins.analytics.titulo}
                </div>
                <div class="cookieConsentPluginField">
                    <input type="checkbox" name="${that.nome}" value="S" />
                </div>
            </div>
        `;
        return html;
    },
    snippet: function(){
        const that = this;
        const body = document.querySelector('body');
        const scriptSource = document.createElement('script');
        scriptSource.src = "https://www.googletagmanager.com/gtag/js?id=" + that.analyticsId;
        scriptSource.async = true;
        body.appendChild(scriptSource)

        scriptSource.onload = function(){
            const scriptBody = document.createElement('script');
            scriptBody.text = `
                window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', '${that.analyticsId}');
            `
            body.appendChild(scriptBody)
        }

    }
}

export {
    PluginAnalitycs
}