import { docCookies } from '../jsSiteDev/cookies.js';

const GereCookies = {
    nome: "cookieConsentV2",
    expiracao: new Date('2999-12-31'),
    verificaCookie: function(){
        const that = this;
        const cookieAtual = docCookies.getItem(that.nome);
        return cookieAtual;
    },
    gravaCookie: function(obj){
        const that = this;
        let cookieFinal = JSON.stringify(obj);
        docCookies.setItem(that.nome, cookieFinal, that.expiracao);
    },
    obtemCookie: function(){
        const that = this;
        return JSON.parse(docCookies.getItem(that.nome));
    }
}

export {
    GereCookies
}