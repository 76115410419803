const CSS = {

    createSheet: function(){
        const that = this;
        const style = document.createElement('style');
        style.type = 'text/css';
        style.id = 'cookieConsentCSS';
        style.innerHTML  = that.cssRules();
        document.getElementsByTagName('head')[0].appendChild(style);
    },
    cssRules: function(){
        const that = this;
        const rules = `
            .cookieConsent { 
                background-color: ${that.backgroundColor}; 
                color: ${that.color};
                position: fixed;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 99999;
                font-size: 14px;
            }
            
            .cookieConsentMensagemInicial{
                padding: 20px;
            }
            
            .cookieConsentMensagemInicialTopo{
                font-size: 24px;
                text-align: center;
            }
            
            .cookieConsentMensagemInicialColunas{
                display: flex;
                justify-content: center;
                align-items: center;
                gap: 30px;
            }
            
            .cookieConsentButton{
                border: 2px solid ${that.color};
                color: ${that.color};
                border-radius: 5px;
                display: block;
                margin-bottom: 10px;
                background-color: transparent;
                padding: 10px;
                cursor: pointer;
            }
            
            .cookieConsentButtonDefault{
                background-color: ${that.color};
                color: ${that.backgroundColor};
                border: 2px solid ${that.backgroundColor};
            }
            
            .cookieConsentDetalhe{
                background-color: ${that.backgroundColor};
                position: fixed;
                left: 0;
                top: 0;
                right: 0;
                bottom: 0;
                z-index: 999999;
                display: none;
            }
            
            .cookieConsentDetalheWrapper{
                background-color: white;
                position: fixed;
                left: 50%;
                transform: translateX(-50%);
                padding: 20px;
                width: calc(100vw  - 40px);
                max-width: 750px;
                top: 20px;
                bottom: 20px;
                z-index: 99999;
                overflow: auto;
                font-size: 14px;
            }
            
            .cookieConsentPlugin{
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                border-top: 1px solid #9fa5a4;
                border-bottom: 1px solid #9fa5a4;
                margin-bottom: 10px;
            }
            
            .cookieConsentPluginTexto sub{
                display: block;
            }
            
            .cookieConsentButoes{
                display: flex;
                justify-content: flex-end;
            }
            
            #cookieConsentPluginsAqui{
                padding-top: 30px;
                padding-bottom: 30px;
            }
            
            #cookieConsentPluginsAqui h2{
                font-size: 20px;
            }
            
            @media only screen and (max-width: 660px){
                .cookieConsentMensagemInicialColunas{
                    flex-direction: column;
                    text-align: center;
                }
                .cookieConsentMensagemInicialDireita{
                    display: flex;
                    gap: 50px;
                }
            }

        
        `;
        return rules;
    },
    backgroundColor: "#f15a2b",
    color: "white"

}

export {
    CSS
}





